import { UspsVerificationData } from 'types/Claim'

import { createContext } from 'react'

export interface ClaimantData {
  isVerified?: boolean
  canReopen?: boolean | null
  uspsVerificationData?: UspsVerificationData | null
}

export interface ClaimantContextType {
  claimantData: ClaimantData | null
  setClaimantData: (data: ClaimantData) => void
}

export const ClaimantContext = createContext<ClaimantContextType>({
  claimantData: null,
  setClaimantData: (_formData: unknown) => {
    return
  },
})
