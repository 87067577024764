import dayjs from 'dayjs'
import { trackEvent } from 'monitoring/events'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en_US from './en-US/translation.json'
import es_US from './es-US/translation.json'

export const defaultNS = 'home'
export const resources = {
  'en-US': {
    auth: en_US.auth,
    home: en_US.home,
    common: en_US.common,
    contact: en_US.contact,
    claimForm: en_US.claimForm,
    featureComponents: en_US.featureComponents,
    whoAmI: en_US.whoAmI,
    reopener: en_US.reopener,
    redirect: en_US.redirect,
    screener: en_US.screener,
    privacy: en_US.privacy,
    errorPages: en_US.errorPages,
    maintenance: en_US.maintenance,
    datePicker: en_US.datePicker,
    uspsIpp: en_US.uspsIpp,
    jobSearch: en_US.jobSearch,
  },
  'es-US': {
    auth: es_US.auth,
    home: es_US.home,
    common: es_US.common,
    contact: es_US.contact,
    claimForm: es_US.claimForm,
    featureComponents: es_US.featureComponents,
    whoAmI: es_US.whoAmI,
    reopener: es_US.reopener,
    redirect: es_US.redirect,
    screener: es_US.screener,
    privacy: es_US.privacy,
    errorPages: es_US.errorPages,
    maintenance: es_US.maintenance,
    datePicker: es_US.datePicker,
    uspsIpp: es_US.uspsIpp,
    jobSearch: es_US.jobSearch,
  },
} as const

export type I18nNamespace = keyof typeof en_US

export const namespaces: Readonly<Array<I18nNamespace>> = [
  'auth',
  'home',
  'common',
  'contact',
  'claimForm',
  'featureComponents',
  'whoAmI',
  'redirect',
  'reopener',
  'screener',
  'privacy',
  'errorPages',
  'maintenance',
  'datePicker',
  'uspsIpp',
  'jobSearch',
] as const

function missingKeyHandler(
  locales: readonly string[],
  namespace: string,
  key: string,
  fallbackValue: string,
  _updateMissing: boolean,
  // According to the docs, this is similar to the t() options,
  // but the differences aren't super clear.
  options: { [key: string]: unknown }
) {
  if (!key.endsWith('_header')) {
    trackEvent('Missing i18n key', {
      i18nContext: typeof options.context === 'string' ? options.context : '',
      i18nKey: key,
      i18nLocales: JSON.stringify(locales),
      i18nNamespace: namespace,
    })
  }
  return fallbackValue
}

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  ns: namespaces,
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false, // React already does escaping
  },
  saveMissing: true,
  missingKeyHandler,
  debug: process.env.NEXT_PUBLIC_ENABLE_I18N_DEBUGGING === 'true',
  supportedLngs: ['en-US', 'es-US'],
  load: 'currentOnly',
})

i18n.services.formatter?.add('long_date', (value, lng, _) => {
  // No formatting for empty values
  if (!value) return ''
  const dayJsValue = dayjs(value)
  return new Intl.DateTimeFormat(lng, {
    // This format will be adjusted slightly for the given language, but it will
    // still contain a fully spelled out month, day and year. For Example,
    // English: August 20, 2023
    // Spanish: 20 de agosto de 2023
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(dayJsValue.toDate())
})
i18n.services.formatter?.add('long_date_time', (value, lng, _) => {
  // No formatting for empty values
  if (!value) return ''
  const dayJsValue = dayjs(value)
  return new Intl.DateTimeFormat(lng, {
    // This format will be adjusted slightly for the given language, but it will
    // still contain a fully spelled out month, day,year and time. For Example,
    // English: August 20, 2023 at 2:45pm p.m.
    // Spanish: 20 de agosto de 2023, 4:09 p.m.
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  }).format(dayJsValue.toDate())
})

export const i18n_home = i18n

export const i18n_common = i18n.cloneInstance({
  defaultNS: 'common',
})

export const i18n_claimForm = i18n.cloneInstance({
  defaultNS: 'claimForm',
})
